<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <!-- Page title -->
                <div class="col-lg-12 text-center">
                    <h2 class="display-3 title">Праздник Труб 2006</h2>
                </div>
                <!-- Page title -->

                <!-- Page content -->
                <div class="col-lg-12">
                    <b-row>
                        <b-col />
                        <b-col>
                            <h3>Аудио</h3> 
                            <b-link href="/media/2006/Part01.mp3" variant="info">Часть 1</b-link><br>
                            <b-link href="/media/2006/Part02.mp3" variant="info">Часть 2</b-link><br>
                            <b-link href="/media/2006/Part03.mp3" variant="info">Часть 3</b-link><br>
                            <b-link href="/media/2006/Interview.mp3" variant="info">Интервью</b-link><br><br> 
                            
                            <h3>Видио</h3> 
                            
                            <h4>Часть 1</h4> 
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/VLfJvYu5XeM" frameborder="0" allowfullscreen /><br><br> 
                            
                            <h4>Часть 2</h4> 
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/9AXUE-kSigw" frameborder="0" allowfullscreen /><br><br> 
                            
                            <h4>Часть 3</h4> 
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/gdCPyYYizQw" frameborder="0" allowfullscreen /><br><br> 
                            
                            <h4>Интервью</h4> 
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/yEg_HvQoGoM" frameborder="0" allowfullscreen /> <br><br>

                            <b-button :to="{name: 'prazdniktrub'}" variant="primary">Вернуться к Праздник труб</b-button>
                        </b-col>
                        <b-col />
                    </b-row>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {};
</script>